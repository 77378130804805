import styled, { css } from "styled-components";

import { Button } from "@adfenix/alexandria";
import Icon from "components/Icon";
import React from "react";

interface Fab {
  fab: boolean;
}

interface IconButtonInterface extends Fab {
  className: string;
  onClick: () => void;
}

const fabCSS = css`
  position: ${({ fab }: Fab) => (fab ? "fixed" : "relative")};
  bottom: ${({ fab }: Fab) => (fab ? "0.5em" : "unset")};
  right: ${({ fab }: Fab) => (fab ? "0.5em" : "unset")};
`;

const RoundButton = styled(({ fab: omit, ...rest }) => <Button {...rest} />)`
  ${fabCSS};
  color: white;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  max-width: 3rem;
  max-height: 3rem;
  padding: 0;
  font-size: 2rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2), 0 -1px 6px 0 rgba(0, 0, 0, 0.5);

  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 1s ease;

  > i {
    color: inherit;
  }

  &:focus {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2), 0 -1px 6px 0 rgba(0, 0, 0, 0.5);
    outline: none;
  }

  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const IconButton = ({
  fab = false,
  className,
  onClick,
}: IconButtonInterface) => (
  <RoundButton fab={fab} variant="link" onClick={onClick}>
    <Icon className={className} />
  </RoundButton>
);

export default IconButton;

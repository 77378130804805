import { Box, Stack } from "@adfenix/alexandria";

import { L98 } from "@adfenix/alexandria";
import Link from "components/Link";
import React from "react";
import Separator from "components/Separator";
import adfenix_primary from "assets/adfenix_primary.svg";
import styled from "styled-components";

const StyledLink = styled(Link)`
  ${({ theme }) => theme.fonts.l};
`;

const SidebarBox = styled(Box)`
  height: fill-available;
  width: 240px; /* Consult design */
  background-color: ${({ theme }) => L98(theme.colors.grey)};
`;

function Navigation() {
  return (
    <SidebarBox
      pX="xl3"
      pY="xl3"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Stack spacing="xl5">
        <img width="64" height="64" src={adfenix_primary} alt="Adfenix" />

        <Stack spacing="gutter" display="flex" flexDirection="column">
          <StyledLink className="uil uil-bullseye" href="/leads">
            Lead settings
          </StyledLink>

          <StyledLink className="uil uil-map" href="/references">
            Reference map
          </StyledLink>

          <Separator />
        </Stack>
      </Stack>
    </SidebarBox>
  );
}

export default Navigation;

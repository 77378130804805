import { noop, not } from "utils";

import React from "react";

const SideBarControlCtx = React.createContext({
  isOpen: true,
  docked: false,
  open: noop,
  close: noop,
  toggle: noop,
  onSetSidebarOpen: (_x: boolean) => noop(),
  enterFullScreen: noop,
  exitFullScreen: noop,
  isFullScreen: false,
});

export function useMediaQueryHandler({
  handler,
  breakpoint,
}: {
  handler: ({ matches }: MediaQueryListEvent) => void;
  breakpoint: number;
}) {
  const ref = React.useRef(handler);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);

    const _handler = ref.current;

    mediaQuery.addListener(_handler);

    return () => mediaQuery.removeListener(_handler);
  }, [breakpoint]);
}

export function SideBarControlProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const breakpoint = 769;
  const [isOpen, setIsOpen] = React.useState(false);
  const [docked, setDocked] = React.useState(window.innerWidth >= breakpoint);

  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const handler = ({ matches }: MediaQueryListEvent) => {
    setIsOpen(false);
    setDocked(matches);
  };

  useMediaQueryHandler({ handler, breakpoint });

  const enterFullScreen = React.useCallback(() => {
    setIsOpen(false);
    setDocked(false);
    setIsFullScreen(true);
  }, [setDocked, setIsOpen]);

  const exitFullScreen = React.useCallback(() => {
    setIsOpen(false);
    setDocked(window.innerWidth >= breakpoint);
    setIsFullScreen(false);
  }, [setIsOpen, setDocked, breakpoint]);

  const value = React.useMemo(
    () => ({
      isOpen,
      docked,
      toggle: () => setIsOpen(not),
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
      onSetSidebarOpen: (val: boolean) => setIsOpen(val),
      enterFullScreen,
      exitFullScreen,
      isFullScreen,
    }),
    [isOpen, docked, setIsOpen, enterFullScreen, exitFullScreen, isFullScreen]
  );

  return (
    <SideBarControlCtx.Provider value={value}>
      {children}
    </SideBarControlCtx.Provider>
  );
}

export const useSideBarControls = () => React.useContext(SideBarControlCtx);

export const useFullScreen = () => {
  const { enterFullScreen, exitFullScreen } = React.useContext(
    SideBarControlCtx
  );
  return { enterFullScreen, exitFullScreen };
};

export const useAutomaticFullScreen = () => {
  const { enterFullScreen, exitFullScreen } = useFullScreen();

  const _enter = React.useRef(enterFullScreen);
  const _exit = React.useRef(exitFullScreen);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      _enter.current();
    }, 16);

    const exit = _exit.current;

    return () => {
      clearTimeout(timer);
      exit();
    };
  }, []);
};

export default SideBarControlProvider;

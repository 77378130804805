import { createGlobalStyle, css } from "styled-components";

const bodyStyle = css`
  color: #002c4b;
  background-color: #f9fafb;
`;

const GlobalStyle = createGlobalStyle`
  * {
    font-family: proxima-nova !important;
  }

  html,body {
    height: 100%;
  }

  body {
    ${bodyStyle}
  }

  optgroup,
  option {
    ${bodyStyle}
  }

  option:disabled {
    background: transparent;

  }

  button:disabled {
    cursor:not-allowed;
  }

  .toast-container {
  text-align: center;
  box-shadow: none;
  min-height: 32px;
  border-radius: 4px;
  font-weight: 600;
  }

`;

export default GlobalStyle;

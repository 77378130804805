import React from "react";
import styled from "styled-components";

const StyledI = styled.i`
  color: ${(props) => props.theme.colors.black};

  :hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Icon = (props: {
  className: string;
  title?: string;
  onClick?: () => void;
}) => <StyledI {...props} aria-hidden="true" />;

export default Icon;

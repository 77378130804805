import { B4, Stack } from "@adfenix/alexandria";

import React from "react";
import styled from "styled-components";

const Container = styled(Stack)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  state = { hasError: false };

  static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? (
      <Container
        spacing="gutter"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <B4>Something went wrong, please try again later</B4>
        <a href="/">Go home </a>
      </Container>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;

import { defaultTheme } from "@adfenix/alexandria";

export const AlexandriaTheme = {
  ...defaultTheme,

  colors: {
    ...defaultTheme.colors,
    primary: "#a01dd8",
  },
};

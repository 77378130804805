export const capitalize = (word: string): string =>
  `${word.charAt(0).toUpperCase()}${word.substring(1)}`;

export const formatDate = (inputDate: string) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };
  const date = new Date(inputDate);
  return date.toLocaleDateString(undefined, options);
};

export const isEmailValid = (email: string | undefined) => {
  const reEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reEmail.test(String(email).toLowerCase());
};

export const RecipientTypes: Array<keyof typeof Api.Recipient> = [
  "Office",
  "Agent",
  "CRM",
  "Custom"
];
export const not = <T>(x: T) => !x;

export const noop: () => void = () => {};

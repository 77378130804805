import React from "react";

const LazyLeadTypes = React.lazy(() =>
  import(/* webpackChunkName: "LeadTypes" */ "containers/LeadTypes")
);

const LazyLeadCreate = React.lazy(() =>
  import(/* webpackChunkName: "LeadCreate" */ "containers/LeadCreate")
);

const LazyCreateReference = React.lazy(() =>
  import(/* webpackChunkName: "CreateReference" */ "containers/CreateReference")
);

const LazyReferenceList = React.lazy(() =>
  import(/* webpackChunkName: "ReferenceList" */ "containers/ReferenceList")
);

const LazyEditReference = React.lazy(() =>
  import(/* webpackChunkName: "EditReference" */ "containers/EditReference")
);

const LazyLeadEdit = React.lazy(() =>
  import(/* webpackChunkName: "LeadEdit" */ "containers/LeadEdit")
);

export function SuspenseLeadEdit<P extends object>(props: P) {
  return (
    <React.Suspense fallback={null}>
      <LazyLeadEdit {...props} />
    </React.Suspense>
  );
}
export function SuspenseLeadTypes<P extends object>(props: P) {
  return (
    <React.Suspense fallback={null}>
      <LazyLeadTypes {...props} />
    </React.Suspense>
  );
}

export function SuspenseLeadCreate<P extends object>(props: P) {
  return (
    <React.Suspense fallback={null}>
      <LazyLeadCreate {...props} />
    </React.Suspense>
  );
}

export function SuspenseCreateReference<P extends object>(props: P) {
  return (
    <React.Suspense fallback={null}>
      <LazyCreateReference {...props} />
    </React.Suspense>
  );
}

export function SuspenseReferenceList<P extends object>(props: P) {
  return (
    <React.Suspense fallback={null}>
      <LazyReferenceList {...props} />
    </React.Suspense>
  );
}

export function SuspenseEditReference<P extends object>(props: P) {
  return (
    <React.Suspense fallback={null}>
      <LazyEditReference {...props} />
    </React.Suspense>
  );
}

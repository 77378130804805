import { HookRouter, useRoutes } from "hookrouter";
import {
  SuspenseEditReference,
  SuspenseCreateReference,
  SuspenseLeadCreate,
  SuspenseLeadEdit,
  SuspenseLeadTypes,
  SuspenseReferenceList
} from "loadables";

import NotFound from "components/NotFound";
import React from "react";

const routes = {
  "/": () => <SuspenseLeadTypes />,
  "/leads": () => <SuspenseLeadTypes />,
  "/leads/:domain": ({ domain }: HookRouter.QueryParams) => (
    <SuspenseLeadTypes domain={domain} />
  ),
  "/lead/:domain/edit/:leadId": ({
    domain,
    leadId
  }: HookRouter.QueryParams) => (
    <SuspenseLeadEdit leadId={leadId} domain={domain} />
  ),
  "/lead/:domain/create": ({ domain }: HookRouter.QueryParams) => (
    <SuspenseLeadCreate domain={domain} />
  ),
  "/references": () => <SuspenseReferenceList />,
  "/references/create/:step": ({ step }: HookRouter.QueryParams) => (
    <SuspenseCreateReference step={parseInt(step)} />
  ),
  "/references/:id/edit": ({ id }: HookRouter.QueryParams) => (
    <SuspenseEditReference id={id} />
  )
};

const Routes = () => {
  return useRoutes(routes) || <NotFound />;
};

export default Routes;

import { A, usePath } from "hookrouter";

import React from "react";
import styled from "styled-components";

const compare = (path: string, href: string) =>
  href === "/" ? path === href : path.includes(href);

interface LinkProps {
  children: React.ReactNode;
  href: string;
  subroute?: string;
  className?: string;
  onClick?: () => void;
}
const Link = ({ href, subroute = "", ...props }: LinkProps) => {
  const path = usePath();
  const active = compare(path, href);
  return <StyledA {...props} href={`${href}${subroute}`} active={active} />;
};

export const StyledA = styled(({ active: omit, subroute: omit2, ...props }) => (
  <A {...props} />
))`
  text-decoration: none;

  &,
  &:visited,
  &:active {
    font-weight: 600;

    color: ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.black};
  }
`;

export default Link;

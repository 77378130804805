import "react-toastify/dist/ReactToastify.css";

import { Slide, ToastContainer } from "react-toastify";

import { Box } from "@adfenix/alexandria";
import IconButton from "components/IconButton";
import Navigation from "containers/Navigation";
import React from "react";
import Sidebar from "components/Sidebar";
import { useSideBarControls } from "hooks/useSideBarControls";

const base = 200;

const styleOverride = {
  sidebar: {
    zIndex: base + 1,
  },
  overlay: {
    zIndex: base,
  },
};

function App({ children }: { children: React.ReactNode }) {
  const { isOpen, docked, toggle, onSetSidebarOpen, isFullScreen } =
    useSideBarControls();

  return (
    <Sidebar
      docked={docked}
      sidebar={<Navigation />}
      open={isOpen}
      onSetOpen={onSetSidebarOpen}
      styles={styleOverride}
    >
      <Box pX="xl5" pY="xl4">
        {children}
        {!docked && !isFullScreen && (
          <IconButton fab className="uil uil-ellipsis-v" onClick={toggle} />
        )}
      </Box>

      {/* 
// @ts-ignore */}
      <ToastContainer
        toastClassName="toast-container"
        position="top-center"
        transition={Slide}
        autoClose={2000}
        hideProgressBar={true}
      />
    </Sidebar>
  );
}

export default App;

import { L40 } from "@adfenix/alexandria";
import React from "react";
import Sidebar from "react-sidebar";
import styled from "styled-components";

const StyledSideBar = styled(({ className, ...rest }) => (
  <Sidebar sidebarClassName={className} {...rest} />
))`
  background-color: ${({ theme }) => L40(theme.colors.grey)};
  border-right: 1px solid ${({ theme }) => L40(theme.colors.grey)};
  box-shadow: none !important;
`;

export default StyledSideBar;

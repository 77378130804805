import * as serviceWorker from "./serviceWorker";

import { Alexandria } from "@adfenix/alexandria";
import { AlexandriaTheme } from "style/alexandriaTheme";
import App from "App";
import { ErrorBoundary } from "containers/ErrorBound";
import GlobalStyle from "style/global";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes";
import SideBarControlProvider from "hooks/useSideBarControls";

const Root = () => (
  <>
    {/* 
  // @ts-ignore */}
    <ErrorBoundary>
      <Alexandria theme={AlexandriaTheme}>
        <SideBarControlProvider>
          <GlobalStyle />
          <App>
            <Routes />
          </App>
        </SideBarControlProvider>
      </Alexandria>
    </ErrorBoundary>
  </>
);

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();

/* eslint-disable import/no-anonymous-default-export */
import { Box, Text } from "@adfenix/alexandria";

import React from "react";

export default () => (
  <Box display="flex" justifyContent="space-around">
    {Array.from({ length: 9 }, (_, i) => (i === 4 ? "?" : 400 + i)).map(
      (num) => (
        <Text key={num} bold>
          {num}
        </Text>
      )
    )}
  </Box>
);
